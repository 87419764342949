#nimbus-graph {
	color: white;
	background-color: #00000090;
	position: absolute;
	bottom: 120px;
	right: -10px;
	z-index: 100;
	text-align: right;
	margin-right: 1px;
	font-color: white;
}

#nimbus-graph p {
	font-size: 12px;
}

#nimbus-graph a {
	color: #1EA7FD;
	text-decoration: none;
}
