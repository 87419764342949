#nimbus-toolbar {
	position: absolute;
	top: 0;
	width: 100%;
	height: 30px;
	z-index: 100;
}

.nimbus-toolbar-icon {
	width: 32px;
	margin: 5px;
	cursor: pointer;
}
