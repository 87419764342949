#nimbus-graph {
	color: white;
	background-color: #00000090;
	position: absolute;
	bottom: 120px;
	right: -10px;
	z-index: 100;
	text-align: right;
	margin-right: 1px;
	font-color: white;
}

#nimbus-graph p {
	font-size: 12px;
	font-color: white;
}

#nimbus-graph a {
	color: white;
	text-decoration: none;
	font-color: white;
}

#nimbus-graph2 {
	color: white;
	background-color: #00000090;
	position: absolute;
	bottom: 120px;
	left: 0px;
	z-index: 100;
	text-align: right;
	margin-right: 1px;
	font-color: white;
}

#nimbus-graph2-title {
	color: white;
	background-color: #00000090;
	position: absolute;
	bottom: 185px;
	left: 0px;
	z-index: 100;
	text-align: right;
	margin-right: 1px;
	font-color: white;
	width: 100%;
}

#nimbus-graph2 p {
	font-size: 12px;
	font-color: white;
}

#nimbus-graph2 a {
	color: #1EA7FD;
	text-decoration: none;
	font-color: white;
}
