#nimbus-name {
	color: white;
	position: absolute;
	bottom: 35px;
	right: 0;
	z-index: 100;
	text-align: right;
	margin-right: 10px;
}

#nimbus-name p {
	font-size: 12px;
	font-weight: bolder;
}
