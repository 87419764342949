.nimbus-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 101;
	background-color: rgba(128, 128, 128, 0.7);
}

.nimbus-modal-content {
	width: 400px;
	min-height: 200px;
	background-color: #ededed;
	position: absolute; /*it can be fixed too*/
	left:0; right:0;
	top:0; bottom:0;
	margin:auto;
	/*this to solve "the content will not be cut when the window is smaller than the content": */
	max-width:100%;
	max-height:100%
}

.nimbus-modal-title {
	border-bottom: 2px solid #CCC;
	padding: 2px 2px 2px 5px;
	font-size: 20px;
	height: 42px;
}

.nimbus-modal-close {
	float: right;
	cursor: pointer;
}

.nimbus-modal-body {
	padding: 5px;
}

.nimbus-modal-actions {
	width: 100%;
	text-align: right;
}

.nimbus-modal-action-button {
	margin-right: 10px;
}
