#nimbus-container {
	height: 100%;
	width: 100%;
	position: relative;
	background-color: black;
	overflow: hidden;
	font-family: Arial, sans-serif;
	font-size: 12px;
}

#isa-logo {
	width: 150px;
	position: absolute;
	bottom: 30px;
	left: 170px;
	opacity: 0.8;
}
