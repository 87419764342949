#nimbus-description {
	color: white;
	position: absolute;
	bottom: 20px;
	right: 0;
	z-index: 100;
	text-align: right;
	margin-right: 10px;
}

#nimbus-description p {
	font-size: 12px;
}

#nimbus-description a {
	color: #1EA7FD;
	text-decoration: none;
}
