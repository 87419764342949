.nimbus-loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 101;
	background-color: rgba(128, 128, 128, 0.7);
}

.nimbus-loading-content {
	width: 400px;
	height: 200px;
	background-color: #ededed;
	position: absolute; /*it can be fixed too*/
	left:0; right:0;
	top:0; bottom:0;
	margin:auto;
	/*this to solve "the content will not be cut when the window is smaller than the content": */
	max-width:100%;
	max-height:100%;
	text-align: center;
}
